import { config } from "@/config/config";
import { Facebook, Instagram as InstagramIco, Linkedin, Mail, MessageCircle, Twitter } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Instagram from "./Instagram";
import SubscribeNewsletter from "./SubscribeNewsletter";

const Footer: React.FC = () => {
  return (
    <>
      <a className="float-whatsapp" target="_blank" rel="noreferrer" href={config.social.whatsapp}>
        <Image src="/novos/img/ico-whatsapp.png" width={100} height={100} priority={true} alt="Ficou com alguma dúvida? Fale com nosso time" />
      </a>
      <footer className="row gap-4 mt-5">
        <SubscribeNewsletter />
        <Instagram />
        <div className="mt-5">
          <div className="container">
            <div className="row row--15 mt_dec--30 ">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <div className="logo">
                    <Link href="/">
                      <Image src="/novos/img/logo-horizontal-colorida.png" width={98} height={50} alt="Clinique Cursos" loading="eager" />
                    </Link>
                  </div>
                  <p className="description mt--20">
                    A Clinique Cursos é uma ótima escolha em medicina, com profissionais de saúde, agendamento fácil e atendimento online de qualidade, tornando o acesso à
                    assistência médica mais conveniente e eficaz, oferecendo cuidados excepcionais.
                  </p>
                  <div className="contact-btn mt--30">
                    <Link className="rbt-btn btn-secondary radius-round" href="/contato">
                      Entre em contato
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Saiba mais</h5>
                  <ul className="ft-link">
                    {/* <li>
                      <Link href={config.politica_privacidade}>Politica de privacidade</Link>
                    </li> */}
                    {/* <li>
                      <Link href={config.termo_uso}>Termo de uso</Link>
                    </li> */}
                    <li>
                      <Link href="/quem-somos">Quem somos</Link>
                    </li>
                    {config.enableModules.showNews && (
                      <li>
                        <Link href="/blog">Blog</Link>
                      </li>
                    )}
                    <li>
                      <Link href="/contato">Atendimento</Link>
                    </li>
                    <li>
                      <Link href="/central-de-ajuda">Central de ajuda</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                <div className="footer-widget">
                  <h5 className="ft-title">Contato</h5>
                  <ul className="ft-link">
                    <li>
                      <a href={config.social.whatsapp} target="_blank">
                        <MessageCircle size={17} /> Nique
                      </a>
                    </li>
                    <li>
                      <a href="/contato" target="_blank">
                        <Mail size={17} /> E-mail
                      </a>
                    </li>
                    <li>Uberlândia / MG</li>
                  </ul>
                  <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                    {config.social.facebook && (
                      <li>
                        <a href={config.social.facebook} target="_blank">
                          <Facebook />
                        </a>
                      </li>
                    )}
                    {config.social.twiter && (
                      <li>
                        <a href={config.social.twiter} target="_blank">
                          <Twitter />
                        </a>
                      </li>
                    )}
                    {config.social.instagram && (
                      <li>
                        <a href={config.social.instagram} target="_blank">
                          <InstagramIco />
                        </a>
                      </li>
                    )}
                    {config.social.linkdin && (
                      <li>
                        <a href={config.social.linkdin} target="_blank">
                          <Linkedin />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="mt-5 mb-5 rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0" />
        </div>
      </div>
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <p className="text-center">Copyright © {new Date().getFullYear()} Todos os direitos reservados.</p>
          </div>
        </div>
      </div>

      <div className="mt-5 rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0" />
        </div>
      </div>
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="">
              <p className="rbt-link-hover text-center text-lg-start">
                Todos os textos, imagens, gráficos, animações, vídeos, músicas, sons e outros materiais são protegidos por direitos autorais e outros direitos de propriedade
                intelectual pertencentes à Clinique. É expressamente vedada a cópia ou reprodução destes materiais para uso ou distribuição comercial, a modificação destes
                materiais, sua inclusão em outros websites e o seu envio e publicação em outros meios digitais e físicos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
